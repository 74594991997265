import type { ComponentProps, ForwardedRef, JSX } from 'react';

import { forwardRef } from '@change/core/react/core';
import { Image } from '@change/design-system/components/media';

import { useQRCode } from 'src/app/shared/hooks/qrcode';

type ImageProps = ComponentProps<typeof Image>;
type QRCodeProps = { value: string; includeMargin?: boolean; size?: number } & ImageProps;
function QRCodeInner(
	{ value, includeMargin = true, size = 100, ...rest }: QRCodeProps,
	ref: ForwardedRef<HTMLImageElement>,
): JSX.Element | null {
	const { data } = useQRCode({ value, size, includeMargin });

	if (!data) return null;

	return (
		<Image src={data} sx={{ width: size, height: size, minWidth: size, minHeight: size }} alt="" {...rest} ref={ref} />
	);
}

export const QRCode = forwardRef(QRCodeInner);
